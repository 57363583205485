<template>
  <div>
    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">Daftar <b>Pengajuan Cetak</b></div>
      <b-button
        squared
        variant="primary"
        @click="$router.push('/print-request/add')"
        v-if="btnAccess"
        >Tambah</b-button
      >
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div>
              <!-- Filter -->
              <div class="row justify-content-end mt-n3">
                <div class="col-md-3">
                  <treeselect
                    v-model="filter.guidance_type_id"
                    :multiple="false"
                    placeholder="Saring Jenis Bimbingan"
                    :options="guidance_types"
                    @input="filterByGuidanceType"
                  />
                </div>
                <div class="col-md-5">
                  <b-input-group>
                    <template #append>
                      <b-button
                        variant="success"
                        squared
                        size="sm"
                        @click="search"
                      >
                        Cari
                      </b-button>
                      <b-button
                        variant="danger"
                        squared
                        size="sm"
                        @click="reset"
                      >
                        Reset
                      </b-button>
                    </template>
                    <b-form-input
                      type="text"
                      v-model="filter.name"
                      placeholder="Saring Nama/NIM"
                    ></b-form-input>
                  </b-input-group>
                </div>
              </div>
              <b-table
                striped
                hover
                :items="items"
                :fields="fields"
                class="mt-3"
                responsive
              >
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: field.key === 'action' ? '15%' : '' }"
                  />
                </template>

                <template #cell(date)="data">
                  <span
                    >{{ data.item.start_date_display }} s/d
                    {{ data.item.end_date_display }}</span
                  >
                </template>

                <template #cell(is_verified_display)="data">
                  <span>
                    <b-badge
                      class="btn-green text-white"
                      style="
                        background-color: #43a047;
                        border-color: #43a047;
                        cursor: pointer;
                      "
                      v-if="data.item.is_verified"
                      @click="
                        currentUser.role_id == 4
                          ? showModalStatus(data.item)
                          : ''
                      "
                    >
                      Terverifikasi
                    </b-badge>
                    <b-badge
                      variant="danger"
                      v-if="!data.item.is_verified"
                      style="cursor: pointer"
                      @click="
                        currentUser.role_id == 4
                          ? showModalStatus(data.item)
                          : ''
                      "
                    >
                      Belum terverifikasi
                    </b-badge>
                  </span>
                </template>

                <template #cell(action)="data">
                  <b-button
                    size="sm"
                    class="mr-1 btn-circle btn-outline-primary"
                    variant="outline-primary"
                    v-b-tooltip.hover
                    title="Cetak"
                    v-if="data.item.is_verified"
                    @click="btnPrintOnClick(data.item)"
                    ><i class="fas fa-print px-0"></i
                  ></b-button>
                  <b-button
                    size="sm"
                    class="mr-1 btn-circle btn-outline-info"
                    v-b-tooltip.hover
                    title="Detail"
                    @click="
                      $router.push({
                        path: '/print-request/detail/' + data.item.id,
                      })
                    "
                    ><i class="fas fa-eye px-0"></i
                  ></b-button>
                  <b-button
                    size="sm"
                    class="mr-1 btn-circle btn-outline-success"
                    v-b-tooltip.hover
                    title="Edit"
                    @click="
                      $router.push({
                        path: '/print-request/edit/' + data.item.id,
                      })
                    "
                    v-if="btnAccess"
                    ><i class="fas fa-edit px-0"></i
                  ></b-button>
                  <b-button
                    size="sm"
                    class="btn-circle btn-outline-danger mr-1"
                    v-b-tooltip.hover
                    title="Hapus"
                    @click="deleteData(data.item.id)"
                    v-if="btnAccess"
                    ><i class="fas fa-trash px-0"></i
                  ></b-button>
                </template>
              </b-table>

              <b-pagination
                v-if="items.length != 0"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                @page-click="pageOnClick"
                class="mt-4"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Status -->
    <b-modal id="modal-status" @ok="changeStatus">
      <template #modal-title>Status</template>
      <b-form-group
        id="input-group-student_name"
        label="Mahasiswa:"
        label-for="input-student_name"
      >
        <b-form-input
          id="input-student_name"
          v-model="formStatus.student_name"
          readonly
        ></b-form-input>
      </b-form-group>
      <b-form-group
        id="input-group-periode"
        label="Periode:"
        label-for="input-periode"
      >
        <b-form-input
          id="input-periode"
          v-model="formStatus.periode"
          readonly
        ></b-form-input>
      </b-form-group>
      <b-form-group id="input-group-description">
        <label for="input-description">Keterangan: </label>
        <b-form-textarea
          id="input-description"
          v-model="formStatus.description"
          placeholder="Keterangan"
          rows="4"
          max-rows="8"
          readonly
        ></b-form-textarea>
      </b-form-group>

      <b-form-group label="" v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          id="radio-group-2"
          v-model="formStatus.is_verified"
          :aria-describedby="ariaDescribedby"
          name="radio-sub-component"
        >
          <b-form-radio value="0">Belum terverifikasi</b-form-radio>
          <b-form-radio value="1">Terverifikasi</b-form-radio>
        </b-form-radio-group>
      </b-form-group>
    </b-modal>

    <!-- Template Print -->
    <Print
      class="d-none"
      :itemProps="studentConsultations"
      :detail="printRequestDetail"
    />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import draggable from "vuedraggable";
import { getUser } from "@/core/services/jwt.service.js";
import Print from "@/view/components/print-request/Print.vue";

export default {
  components: {
    draggable,
  },
  components: {
    Print,
  },
  data() {
    return {
      currentUser: getUser(),
      // filter
      filter: {
        name: "",
        guidance_type_id: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "student_name",
          label: "Mahasiswa",
          sortable: true,
        },
        {
          key: "guidance_type_name",
          label: "Jenis Bimbingan",
          sortable: true,
        },
        {
          key: "date",
          label: "Periode",
          sortable: true,
        },
        {
          key: "is_verified_display",
          label: "Status",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          tdClass: "nowrap",
        },
      ],
      items: [],
      formStatus: {
        id: "",
        is_verified: "",
        student_name: "",
        periode: "",
        description: "",
      },
      errorStatus: {
        id: "",
        is_verified: "",
      },
      studentConsultations: [],
      printRequestDetail: {},
      guidance_types: [],
      // access
      btnAccess: false,
    };
  },
  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      //("evt", evt);
      //("page", page);
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}&guidance_type_id=${this.filter.guidance_type_id}`;

      if (this.currentUser.role_id == 2) {
        filterParams += `&student_id=${this.currentUser.id}`;
      }

      let response = await module.paginate(
        "api/print-requests",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    filterByGuidanceType(evt){
      if(typeof evt == "undefined"){
        this.filter.guidance_type_id = ''
      }

      this.pagination()
    },
    search() {
      this.pagination();
    },
    reset() {
      this.filter.name = "";
      this.pagination();
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("api/print-requests/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    async getGuidanceTypeOption() {
      let response = await module.setTreeSelect(
        "api/guidance-types?page_size=1000"
      );
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.guidance_types = response.data;
        this.guidance_types.unshift({
          label: "Pilih Jenis Bimbingan",
          id: '',
          isDisabled: true,
        });
      }
    },

    showModalStatus(value) {
      this.formStatus.id = value.id;
      this.formStatus.is_verified = value.is_verified;
      this.formStatus.student_name = value.student_name;
      this.formStatus.description = value.description;
      this.formStatus.periode =
        value.start_date_display + " s/d " + value.end_date_display;

      this.$bvModal.show("modal-status");
    },

    async changeStatus() {
      // Make Request
      let response = await module.submit(
        this.formStatus,
        `api/print-requests/${this.formStatus.id}/verification`
      );
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire({
          title: response.success.title,
          text: response.success.message,
          icon: "success",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.pagination();
        this.$bvModal.hide("modal-status");
      }
    },

    async btnPrintOnClick(data) {
      let filterParams = `&guidance_type_id=${data.guidance_type_id}&student_id=${data.student_id}&start_date=${data.start_date}&end_date=${data.end_date}`;
      // let filterParams = `&guidance_type_id=${data.guidance_type_id}&student_id=${data.student_id}`;
      let response = await module.paginate(
        "api/student-consultations",
        `?page=1&page_size=200${filterParams}`
      );
      this.studentConsultations = response.data;
      this.printRequestDetail = data;

      setTimeout(() => {
        this.print();
      }, 1000);
    },

    async print() {
      await this.$htmlToPaper("printMe");
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1314") {
          this.btnAccess = true;
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Pengajuan Cetak", route: "/print-request" },
    ]);
    this.pagination();
    this.checkAccess();
    this.getGuidanceTypeOption();
  },
};
</script>

<style>
.nowrap {
  white-space: nowrap;
}
</style>

<template>
  <div id="printMe">
    <div class="row">
      <div class="col-md-12">
        <div class="card border-none" style="border: none">
          <div class="card-body">
            <div class="row">
              <div class="col-5">
                <table class="table table-borderless">
                  <tr style="line-height: 0">
                    <th>Nama</th>
                    <th>:</th>
                    <td>
                      {{
                        itemProps.length > 0 ? itemProps[0].student_name : ""
                      }}
                    </td>
                  </tr>
                  <tr style="line-height: 0">
                    <th>NIM</th>
                    <th>:</th>
                    <td>
                      {{
                        itemProps.length > 0
                          ? itemProps[0].student_registration_number
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr style="line-height: 0">
                    <th width="135" style="white-space: nowrap">Jenis Bimbingan</th>
                    <th>:</th>
                    <td>
                      {{
                        detail.guidance_type_name
                      }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div>
              <b-table
                bordered
                :items="itemProps"
                :fields="fields"
                class="mt-3"
                responsive
              >
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: field.key === 'action' ? '15%' : '' }"
                  />
                </template>

                <template #cell(number)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(student_name)="data">
                  <span class="d-block">{{ data.item.student_name }} </span>
                  <span class="d-block"
                    >{{ data.item.student_registration_number }}
                  </span>
                </template>

                <template #cell(status_id)="data">
                  {{ data.item.status_display }}
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    itemProps: Array,
    detail: Object,
  },
  data() {
    return {
      fields: [
        {
          key: "number",
          label: "#",
        },
        {
          key: "consultation_date_display",
          label: "Tgl. Konsultasi",
        },
        {
          key: "teacher_1_name",
          label: "Pembimbing",
        },
        {
          key: "consultation_date_display",
          label: "Tgl. Konsultasi",
        },
        // {
        //   key: "status_id",
        //   label: "Status",
        //   tdClass: "nowrap",
        // },
        // {
        //   key: "verification_date_display",
        //   label: "Tgl. Konfirmasi",
        //   thClass: "nowrap",
        // },
        {
          key: "consultation_content",
          label: "Isi Bimbingan",
          thClass: "nowrap",
        },
      ],
    };
  },
};
</script>

<style>
.nowrap {
  white-space: nowrap;
}
</style>
